import React from 'react'

import HeaderPage from '../components/HeaderPage'
import Page from '../components/Page'
import About from '../components/About'
import Statistic from '../components/Statistic'
import Cta from '../components/Cta'
import Mission from '../components/Mission'
import Download from '../components/Download'
import FooterPage from '../components/FooterPage'

function AboutPage() {
	return (
		<div className="page__wrapper">
			<HeaderPage active="about" />
			<Page name="About" />
			<About />
			<Statistic />
			<Cta />
			<Mission pages="about" type="section-title__default" />
			<Download />
			<FooterPage current="about" />
		</div>
	);
}

export default AboutPage