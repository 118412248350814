import React from 'react'

import Title from './Title'

function Mission(props) {
	return (
		<section id="mission_block" className={props.pages}>
			<Title
				type={props.type}
				text="Our Mission"
			/>
			<div className="container">
				<div className="mission">
					<div className="mission__block mission__block--first">
						<h4 className="mission__title">We help remote workers automate the reporting process</h4>
						<p className="mission__text">The HeyBoss project was created for people who work remotely, or partially remotely, or in a large company, and are not in the same office all the time. In this regard, it's important for a person to be in touch with his boss, so that he knows where and what the person is doing now. Since location reports, or short reports, are time-consuming, our app speeds up and streamlines the process of communicating with the boss.</p>
					</div>
					<div className="mission__block mission__block--second">
						<h4 className="mission__title">Our Focus</h4>
						<p className="mission__text">We save time of the employee and the boss, simplify and optimize the process of their communication. Convenience decides everything!</p>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Mission