import React from 'react'

import HeaderPage from '../components/HeaderPage'
import Page from '../components/Page'
import Title from '../components/Title'
import FooterPage from '../components/FooterPage'

function PrivacyPage() {
	return (
		<div className="page__wrapper">
			<HeaderPage />
			<Page name="Privacy" />
			<section className="page page__privacy">
				<div className="container">
					<Title
						type="section-title__default"
						text="Privacy Policy"
					/>
					<div className="page__content">

						<p className="page__text"><a href="https://heyboss.online/">https://heyboss.online/</a> website administration is obliged to maintain your privacy on the Internet. We pay great attention to securing the data you provided to us. Our privacy policy is based on <a href="https://ec.europa.eu/info/law/law-topic/data-protection_en">the General Data Protection Regulation (GDPR) of the European Union</a>. The purposes, for which we collect your personal data are: improvement of our service, communication with visitors (users) to this site, presenting the information requested by the user, location, and for other actions listed below.</p>

						<h3 className="page__subtitle">We notes in this privacy policy the following:</h3>

						<p className="page__text">This privacy policy covers all data obtained through the site <a href="https://heyboss.online/">https://heyboss.online/</a>.</p>

						<p className="page__text">In case of filling in the form on <a href="https://heyboss.online/">https://heyboss.online/</a> the user (any person who has filled in the form) confirms in this way that he/she has read this privacy policy and consents to the processing of personal data. All these norms and provisions are clear to the user. The user also confirms that he has the required amount of civil capacity. His expression of will is free and corresponds to his inner will.</p>

						<p className="page__text">Data can only be transmitted by adults. It is forbidden to fill in forms on the site <a href="https://heyboss.online/">https://heyboss.online/</a> or data transfer in any other way to minors.</p>

						<h3 className="page__subtitle">Personal data storage and processing</h3>

						<p className="page__text">We collect and process your personal data only with your willing consent. With your permission, we can collect and process the following data: email address, phone number, location. Collection and processing of your personal information is carried out in accordance with the laws of the European Union and the Ukraine.
						Data storage, alteration, and removal.</p>

						<p className="page__text">The user, who has provided us with their personal data, has the right for their alteration and removal, as well as the right to recall the agreement to data processing. Time, during which your personal data will be stored is: time the personal data is used that is necessary for the website’s main activity. After finishing with the processing of your personal data, the website’s administration will permanently delete it. To access your personal data, you can contact the administration on: <a href="mailto:admin@heyboss.online">admin@heyboss.online</a>.</p>

						<p className="page__text">Personal data may be transferred to third parties only if: the personal data subject has consented; if it is provided or allowed by law; if, third parties are companies (business entities) that provide us services  or have any relation to our economic activity. For example, with service providers that perform certain functions on our behalf, such as payment processing, hosting, email processing, and more. These companies may process the personal data of the personal data subject to perform functions on our behalf.</p>

						<p className="page__text">If the data was transferred to a third party, which is not associated with our organization, we cannot perform any changes to that data.</p>

						<h3 className="page__subtitle">Processing of visiting technical data</h3>

						<p className="page__text">Records of your IP address, time of visit, browser settings, operational system and other technical information is saved in the database when you visit <a href="https://heyboss.online/">https://heyboss.online/</a>. This data is necessary for the correct display of the website’s content. It is impossible to identify the person of the visitor using this data.</p>

						<h3 className="page__subtitle">Children’s personal information</h3>

						<p className="page__text">If you are a parent or a legal guardian of an underage child, and you know that the child has provided us with their personal information without your consent, please contact us on: <a href="mailto:admin@heyboss.online">admin@heyboss.online</a>.
						Cookies processing</p>

						<p className="page__text">We use cookies and similar technologies, such as tags and pixels ("Cookies"), to personalize and enhance the performance of our customers while they use our website or mobile application, and to provide them with relevant online advertising.</p>

						<p className="page__text">We use cookie files for the correct display of the website’s content and for the convenience of browsing <a href="https://heyboss.online/">https://heyboss.online/</a>. They are small files that are stored on your device. They help the website to remember information about you, such as in which language you use the website and which pages have you already opened. This information will be useful in the next visit. Thanks to cookie files, the website’s browsing becomes much more convenient. You can set up cookie reception and blocking in your browser yourself. Inability to receive cookie files may limit the functionality of the website.</p>

						<p className="page__text">Please note that cookies cannot damage the personal data subject's computer. We do not store any personally identifiable or confidential information, such as credit card details, in cookies.</p>

						<h3 className="page__subtitle">Processing of personal data by other services</h3>

						<p className="page__text">This website uses third-party online services, which perform data collecting, independent from us. Such services include: Google and  Facebook.</p>

						<p className="page__text">Data collected by these services may be provided to other services within those organizations. They can use the data for advertising personalization of their own advertising network. You can learn about user agreements of those organizations on their websites. You can also refuse their collection of your personal data. For example, Google Analytics Opt-out Browser Add-on can be found <a href="https://tools.google.com/dlpage/gaoptout?hl=en">here</a>. We do not pass any personal data to other organizations or services, which are not mentioned in this privacy policy. As an exception, the collected data may be provided on a lawful request of state authorities that are authorized to request such information.</p>

						<h3 className="page__subtitle">Links to other websites</h3>

						<p className="page__text">Our website <a href="https://heyboss.online/">https://heyboss.online/</a> may contain links to other websites, which are not under our control. We are not responsible for the content of these websites. We recommend you familiarize yourself with the privacy policy of every website that you visit, if such policy exists. We also are not responsible for content or cookies of such sites.</p>

						<p className="page__text">Despite the use of appropriate technical and organizational methods of personal data protection of the site <a href="https://heyboss.online/">https://heyboss.online/</a>  we cannot be responsible for the protection of such data as a result of illegal actions of third parties.</p>

						<h3 className="page__subtitle">Changes to the privacy policy</h3>

						<p className="page__text">The privacy policy may be changed without the prior consent of the data subject. Such information is open, posted on the site <a href="https://heyboss.online/">https://heyboss.online/</a>, so the subject of personal data has the opportunity to get acquainted with it at any time.</p>

						<p className="page__text">We are monitoring any changes in legislation, which is related to personal data in European Union and Ukraine. If you have entered any of your personal data on our website, we will inform you about the changes in our privacy policy. If your personal data, and more specifically, your contact information was entered incorrectly, we will not be able to contact you.</p>

						<h3 className="page__subtitle">Feedback and final clauses</h3>

						<p className="page__text">You can contact our administration regarding any questions related to privacy policy on: <a href="mailto:admin@heyboss.online">admin@heyboss.online</a>, or by filling a contact form specified in a corresponding section of this website. If you do not agree with this privacy policy, you cannot use the services of <a href="https://heyboss.online/">https://heyboss.online/</a>. In this case you should avoid visiting our website.</p>

						<h3 className="page__subtitle">CONTACT INFORMATION OF THE RESPONSIBLE PERSON</h3>

						<small>NGO "HEY BOSS", Ukraine, 65023 Odessa region, Odessa, Odessa, Nizhynska Street 34, apartment 6. Osipenko Antonina Tarasivna - President, <a href="mailto:admin@heyboss.online">admin@heyboss.online</a>, phone: <a href="tel:+380734735002">+38073 473 50 02</a></small>
						<br /><br />
						<small>Application HeyBoss is developed by NGO “Ukrainian People's Control over Digitalization", Ukraine, 65007, Odessa region, Odessacity, Primorsky district, Staroportofrankivska street, 107 apartment 21/ ANDRII ROGOVSKYI - head of NGO “Ukrainian People's Control over Digitalization", <a href="mailto:admin@unkd.com.ua">admin@unkd.com.ua</a>, phone: <a href="tel:+380632640519">+38 63 264 05 19</a></small>
					</div>
				</div>
			</section>
			<FooterPage current="privacy" />
		</div>
	);
}

export default PrivacyPage