import React from 'react'
import { Link } from 'react-router-dom'
import partner001 from '../assets/img/icons/partners/android.svg'
import partner002 from '../assets/img/icons/partners/apple.svg'
import partner003 from '../assets/img/icons/partners/windows.svg'
import partner004 from '../assets/img/icons/partners/github.svg'
import partner005 from '../assets/img/icons/partners/dropbox.svg'
import partner006 from '../assets/img/icons/partners/sandbox.svg'
import partner007 from '../assets/img/icons/partners/codepen.svg'
import partner008 from '../assets/img/icons/partners/sketch.svg'

function FooterPage(props) {
	return (
		<footer className="footer" current={props.current}>
			<div className="container text-center">
				<div className="partners">
					<span className="partners__link">
						<img loading="lazy" src={partner001} className="partners__image" alt="Android" />
					</span>
					<span className="partners__link">
						<img loading="lazy" src={partner002} className="partners__image" alt="Apple" />
					</span>
					<span className="partners__link">
						<img loading="lazy" src={partner003} className="partners__image" alt="Windows" />
					</span>
					<span className="partners__link">
						<img loading="lazy" src={partner004} className="partners__image" alt="Github" />
					</span>
					<span className="partners__link">
						<img loading="lazy" src={partner005} className="partners__image" alt="Dropbox" />
					</span>
					<span className="partners__link">
						<img loading="lazy" src={partner006} className="partners__image" alt="Sandbox" />
					</span>
					<span className="partners__link">
						<img loading="lazy" src={partner007} className="partners__image" alt="Codepen" />
					</span>
					<span className="partners__link">
						<img loading="lazy" src={partner008} className="partners__image" alt="Sketch" />
					</span>
				</div>
				<div className="menu--footer__page">
					<div>
						<Link to="/about" className="menu--footer__link" data-active="about">About</Link>
						<Link to="/product" className="menu--footer__link" data-active="product">Product</Link>
						<Link to="/reviews" className="menu--footer__link" data-active="reviews">Reviews</Link>
						<Link to="/privacy" className="menu--footer__link" data-active="privacy">Privacy Policy</Link>
					</div><br /><br />
					<p className="menu--footer__text">HeyBoss - &copy;2021. All Rights Reserved</p>
				</div><br /><br />
				<p className="menu--footer__text"><small>HeyBoss is approved by the Internal Revenue Service as a 501 (C) (3) tax-exempt organization, and all donations are tax deductible to the extent provided by law.</small></p>
			</div>
		</footer>
	);
}

export default FooterPage