import React from 'react'

import MenuPage from './MenuPage'

function HeaderPage(props) {
	return (
		<section id="top_block_page" active={props.active}>
			<div className="container">
				<div className="hb-burger">
					<span className="one"></span>
					<span className="two"></span>
					<span className="three"></span>
				</div>
				<MenuPage></MenuPage>
			</div>
		</section>
	);
}

export default HeaderPage