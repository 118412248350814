import * as $ from 'jquery'

$(document).ready(function () {
  $('.hb-burger').on('click', function () {
    $(this).toggleClass('opened');
    $('.hb-menu').toggleClass('mob-menu');
    $('body').toggleClass('fix-mob');
  });

  $('.hb-menu__link').on('click', function () {
    $('.hb-burger').toggleClass('opened');
    $('.hb-menu.mob-menu').removeClass('mob-menu');
    $('body').removeClass('fix-mob');
  });

  $('.hb-menu .btn.btn--premium').on('click', function () {
    $('.hb-burger').toggleClass('opened');
    $('.hb-menu.mob-menu').removeClass('mob-menu');
    $('body').removeClass('fix-mob');
  });

  $('.home_up').on('click', function () {
    $('.scroll.active').removeClass('active');
    $('.home_up').addClass('active');
  });

  $('.about_up').on('click', function () {
    $('.scroll.active').removeClass('active');
    $('.about_up').addClass('active');
  });

  $('.product_up').on('click', function () {
    $('.scroll.active').removeClass('active');
    $('.product_up').addClass('active');
  });

  $('.reviews_up').on('click', function () {
    $('.scroll.active').removeClass('active');
    $('.reviews_up').addClass('active');
  });

  $('.btn.btn--premium.scroll').on('click', function () {
    $('.hb-menu__link.scroll.active').removeClass('active');
  });

  $('.about__list--problems .first').on('click', function () {
    $('.about__list .active').removeClass('active');
    $('.about__dot.active').removeClass('active');
    $(this).addClass('active');
    $('.about__dot--first, .about__list--solutions .first').addClass('active');
  });
  $('.about__list--problems .second').on('click', function () {
    $('.about__list .active').removeClass('active');
    $('.about__dot.active').removeClass('active');
    $(this).addClass('active');
    $('.about__dot--second, .about__list--solutions .second').addClass('active');
  });
  $('.about__list--problems .third').on('click', function () {
    $('.about__list .active').removeClass('active');
    $('.about__dot.active').removeClass('active');
    $(this).addClass('active');
    $('.about__dot--third, .about__list--solutions .third').addClass('active');
  });
  $('.about__list--problems .fourth').on('click', function () {
    $('.about__list .active').removeClass('active');
    $('.about__dot.active').removeClass('active');
    $(this).addClass('active');
    $('.about__dot--fourth, .about__list--solutions .fourth').addClass('active');
  });

  $(".scroll").click(function (event) {
    event.preventDefault();
    var full_url = this.href;
    var parts = full_url.split("#");
    var trgt = parts[1];
    var target_offset = $("#" + trgt).offset();
    var target_top = target_offset.top - 78;
    $('html, body').animate({ scrollTop: target_top }, 1500);
  });
});