import React from 'react'
import partner001 from '../assets/img/icons/partners/android.svg'
import partner002 from '../assets/img/icons/partners/apple.svg'
import partner003 from '../assets/img/icons/partners/windows.svg'
import partner004 from '../assets/img/icons/partners/github.svg'
import partner005 from '../assets/img/icons/partners/dropbox.svg'
import partner006 from '../assets/img/icons/partners/sandbox.svg'
import partner007 from '../assets/img/icons/partners/codepen.svg'
import partner008 from '../assets/img/icons/partners/sketch.svg'

function Footer() {
  return (
    <footer className="footer">
      <div className="container text-center">
        <div className="partners">
          <span className="partners__link">
            <img loading="lazy" src={partner001} className="partners__image" alt="Android" />
          </span>
          <span className="partners__link">
            <img loading="lazy" src={partner002} className="partners__image" alt="Apple" />
          </span>
          <span className="partners__link">
            <img loading="lazy" src={partner003} className="partners__image" alt="Windows" />
          </span>
          <span className="partners__link">
            <img loading="lazy" src={partner004} className="partners__image" alt="Github" />
          </span>
          <span className="partners__link">
            <img loading="lazy" src={partner005} className="partners__image" alt="Dropbox" />
          </span>
          <span className="partners__link">
            <img loading="lazy" src={partner006} className="partners__image" alt="Sandbox" />
          </span>
          <span className="partners__link">
            <img loading="lazy" src={partner007} className="partners__image" alt="Codepen" />
          </span>
          <span className="partners__link">
            <img loading="lazy" src={partner008} className="partners__image" alt="Sketch" />
          </span>
        </div>
        <div className="menu--footer">
          <p className="menu--footer__text">HeyBoss - &copy;2021. All Rights Reserved</p>
          <div>
            <a className="menu--footer__link scroll home_up" href="#site_content">Home</a>
            <a className="menu--footer__link scroll about_up" href="#about_block">About</a>
            <a className="menu--footer__link scroll product_up" href="#product_block">Product</a>
            <a className="menu--footer__link scroll reviews_up" href="#reviews_block">Reviews</a>
            <a className="menu--footer__link" href="/privacy">Privacy Policy</a>
          </div>
        </div><br /><br />
        <p className="menu--footer__text"><small>HeyBoss is approved by the Internal Revenue Service as a 501 (C) (3) tax-exempt organization, and all donations are tax deductible to the extent provided by law.</small></p>
      </div>
    </footer>
  );
}

export default Footer