import React from 'react'
import Header from '../components/Header'
import Intro from '../components/Intro'
import About from '../components/About'
import Statistic from '../components/Statistic'
import Cta from '../components/Cta'
import Product from '../components/Product'
import Reviews from '../components/Reviews'
import Download from '../components/Download'
import Mission from '../components/Mission'
import Footer from '../components/Footer'

function HomePage() {
  return (
    <div>
      <Header />
      <Intro />
      <About />
      <Statistic />
      <Cta />
      <Product />
      <Reviews />
      <Download />
      <Mission pages="home" type="section-title__invert" />
      <Footer />
    </div>
  )
}

export default HomePage