import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import './styles/custom.sass'

import HomePage from './pages/HomePage'
import AboutPage from './pages/AboutPage'
import ProductPage from './pages/ProductPage'
import ReviewsPage from './pages/ReviewsPage'
import PrivacyPage from './pages/PrivacyPage'
import DownloadPage from './pages/DownloadPage'
import HeaderPage from './components/HeaderPage'

function App() {
  return (
    <BrowserRouter>
      <HeaderPage />
      <div id="site_content">
        <div className="content">
          <Switch>
            <Route
              path="./hey_boss_app.png"
              render={() => <Redirect to="https://heyboss.online/hey_boss_app.png" />}
            />
            <Route exact path="/" component={HomePage} />
            <Route path="/about" component={AboutPage} />
            <Route path="/product" component={ProductPage} />
            <Route path="/reviews" component={ReviewsPage} />
            <Route path="/privacy" component={PrivacyPage} />
            <Route path="/download" component={DownloadPage} />
            <Redirect to="/" />
          </Switch>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App
