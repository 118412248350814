import React from 'react'

import HeaderPage from '../components/HeaderPage'
import Page from '../components/Page'
import Reviews from '../components/Reviews'
import Mission from '../components/Mission'
import Download from '../components/Download'
import FooterPage from '../components/FooterPage'

function ReviewsPage() {
	return (
		<div className="page__wrapper">
			<HeaderPage active="reviews" />
			<Page name="Reviews" />
			<Reviews pages="reviews" />
			<Mission pages="about" type="section-title__default" />
			<Download />
			<FooterPage current="reviews" />
		</div>
	);
}

export default ReviewsPage