import React from 'react'

import HeaderPage from '../components/HeaderPage'
import Page from '../components/Page'
import Mission from '../components/Mission'
import Download from '../components/Download'
import FooterPage from '../components/FooterPage'

function DownloadPage() {
	return (
		<div className="page__wrapper">
			<HeaderPage />
			<Page name="Download" />
			<Mission pages="about" type="section-title__default" />
			<Download />
			<FooterPage />
		</div>
	);
}

export default DownloadPage