import React from 'react'
import { Link } from 'react-router-dom'

function Page(props) {
	return (
		<section className="page">
			<div className="page__header">
				<div className="container">
					<div className="page__navigation">
						<h3 className="page__heading">{props.name}</h3>
						<div className="page__pagination">
							<Link to="/" className="page__link">Home</Link>
							<p className="page__link page__link--row">&rsaquo;</p>
							<p className="page__link page__link--active">{props.name}</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Page