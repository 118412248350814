import React from 'react'

import Survey from './Survey'

function Intro() {
  return (
    <div className="intro">
      <div className="container text-center">
        <h2 className="intro__subtitle">Remote work must be Easy and Comfortable</h2>
        <h1 className="intro__title">Hey Boss! I'm on my way back</h1>
        <p className="intro__text">Happy Boss - Happy Employee <span className="intro__smile">😋</span></p>
        <Survey />
      </div>
    </div>
  )
}

export default Intro