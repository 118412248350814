import React from 'react'
import logo from '../logo.svg'

function Menu() {
  return (
    <div className="hb-header">
      <h1 className="hb-logo">
        <a className="hb-logo__link scroll" href="#site_content">
          <img loading="lazy" src={logo} className="hb-logo__img" alt="HeyBoss" />
          <h1 className="hb-logo__text">HeyBoss</h1>
        </a>
      </h1>
      <div className="hb-menu">
        <a className="hb-menu__link scroll top_menu_link about_up" href="#about_block">About</a>
        <a className="hb-menu__link scroll to_product_link product_up" href="#product_block">Product</a>
        <a className="hb-menu__link scroll top_menu_link reviews_up" href="#reviews_block">Reviews</a>
        <a className="btn btn--premium scroll top_menu_link" href="#download_block">Download Now</a>
      </div>
    </div>
  );
}

export default Menu