import React from 'react'
import logo from '../logo.svg'
import { Link } from 'react-router-dom'

function MenuPage() {
	return (
		<div className="hb-header">
			<h1 className="hb-logo">
				<Link to="/" className="hb-logo__link">
					<img loading="lazy" src={logo} className="hb-logo__img" alt="HeyBoss" />
					<h1 className="hb-logo__text">HeyBoss</h1>
				</Link>
			</h1>
			<div className="hb-menu">
				<Link to="/about" className="hb-menu__link about_page" current="about">About</Link>
				<Link to="/product" className="hb-menu__link product_page" current="product">Product</Link>
				<Link to="/reviews" className="hb-menu__link reviews_page" current="reviews">Reviews</Link>
				<Link to="/download" className="btn btn--premium">Download Now</Link>
			</div>
		</div>
	);
}

export default MenuPage