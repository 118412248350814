import React from 'react'

import Title from './Title'
const formUrl = 'https://docs.google.com/forms/d/e/1FAIpQLScIAD7R5fvJsB8aNNVHF0zfi9rEPrlzQyhC4Si9ehfJzxJcFw/viewform?usp=pp_url'

function Cta() {
  return (
    <section id="cta_block">
      <Title
        type="section-title__blue"
        text="Best Сonvenient Way of Notifications"
      />
      <div className="container text-center">
        <div className="cta">
          <p className="cta__text">You need to create reminders for the boss about some important events that you have already planned, and do not happen spontaneously. Your birthday, business meetings, negotiations, appointments. Everything about which the boss can be notified in advance, and depending on the importance or time of your absence, send reminders periodically or once.</p>
          <a className="btn btn--premium btn--big cta__link" href={formUrl}>Download Now</a>
        </div>
      </div>
    </section>
  );
}

export default Cta